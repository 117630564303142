.gallery-game {
    text-align: center;
    padding: 2rem;
    margin-top: 100px;
  }
  
  .blues-image {
    width: 70%;
    height: auto;
    border-radius: 10px;
    margin: 2rem 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
  }
  
  .option-button {
    padding: 1rem 2rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    transition: background-color 0.3s ease;
  }
  
  .option-button:hover {
    background-color: #0056b3;
  }

  .option-button:cli {
    background-color: #0056b3;
  }
  
  .score {
    font-size: 1.5rem;
    margin-top: 2rem;
    position: fixed;
    top:50px;
    right: 10px;
  }

 .retry-button{
    background-color: #007bff;
    padding: .5rem 1.5rem;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s ease;
 }

 .retry-button:hover {
    background-color: #0056b3;
  }

  .gallery-footer {
    text-align: center;
    margin-top: 2rem;
    padding: 1rem;
    font-size: 1.2rem;
    color: #333;
  }
  
  .arrow-down {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #333;
    margin: 1rem auto;
  }
  
  
