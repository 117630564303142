.meet-the-team-container {
  text-align: center;
  padding: 2rem;
  margin-top: 100px;
}

.team-members {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.team-member {
  perspective: 1000px; /* perspective for 3D effect */
}

.flip-card {
  background-color: transparent;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-card-front {
  background-color: #fff;
}

.flip-card-back {
  background-color: #007bff;
  color: white;
  transform: rotateY(180deg);
  flex-direction: column;
}

.team-member-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.team-member h2 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.team-member p {
  font-size: 1rem;
  color: #fff;
}

.team-member p2 {
  font-size: 1rem;
  color: #666;
}

.instagram-handle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


.instagram-icon {
  font-size: 1.2rem;
}