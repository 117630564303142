.footer {
    background-color: #333;
    color: #fff;
    padding: 1rem 0;
    text-align: center;
    border-top: 1px solid #444;
  }
  
  .footer .social-links {
    display: flex;
    justify-content: center;
    gap: 2rem;
  }
  
  .footer .social-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .footer .social-links a:hover {
    color: #10abd9;
  }
  
  .footer .social-links span {
    font-size: 1rem;
  }
  