.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  overflow-x: hidden; /* No horizontal overflow */
}


.content-container {
  position: relative;
  z-index: 1; /* Ensure the content is above the background images */
}


.left-arrow,
.right-arrow {
  position: absolute;
  top: 50%;
  font-size: 3rem;
  color: #ffffff42;
  cursor: pointer;
  user-select: none;
  z-index: 2; /* Ensure arrows are above the background images */
}

.left-arrow {
  left: 32px;
}

.right-arrow {
  right: 32px;
}

.left-arrow:hover,
.right-arrow:hover {
  color: #fff; 
}


.indicator-container {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 2; /* Ensure indicators are above the background images */
}

.indicator {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background-color: #ffffff42;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.indicator.active {
  background-color: #fff;
  transform: scale(1.1);
}