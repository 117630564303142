.weather-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  width: 80%; 
  max-width: 600px; 
  text-align: center;
  z-index: 1000; 
}

.weather-popup-content {
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weather-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
}

.weather-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 10px;
  width: 120px; 
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #7fb2e6;
}

.weather-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.weather-date {
  font-weight: bold;
}

.weather-popup p2{
  font-style: italic;
  font-weight: bold;
}
