@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

:root{
    --mainColour: #10abd9;
    --mainColourLight: #7fb2e6;
    --secondaryColour:#c1d4f1;
    --textColour: #ffffff;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;


    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 2rem;
    background-color: var(--mainColour);
    color: var(--textColour)
}

nav a{
    margin: 0 2rem;
    color: var(--textColour);
    text-decoration: none;
    font-size: 1.2rem;
}

nav a:hover{
    color: var(--secondaryColour)
}

header .nav-btn{
    padding: 5px;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    color: var(--textColour);
    visibility: hidden;
    opacity: 0;
    font-size:  1.8rem;
}

header .nav-btn:hover{
    color: var(--secondaryColour);
}

@media only screen and (max-width: 1100px){
    header .nav-btn{
        visibility: visible;
        opacity:1;
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--mainColour);
        transition: 1s;
        transform: translateY(-100vh);
    }

    header .responsive_nav{
        transform: none;
    }

    nav .nav-close-btn{
        position: absolute;
        top: 2rem;
        right: 2rem;
    }

    nav a{
        font-size: 1.5rem;
    }

}

.logo {
    height: 50px;
    width: auto;
    transition: transform 0.5s ease-in-out;
}

.logo:hover {
    transform: rotate(360deg);
}

.logowords {
    height: 50px;
    position: absolute;
    left: 100px;
}


.background-container {
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.fade-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fade-image.active {
  opacity: 1;
}


.main-content {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
    text-align: center;
    padding: 2rem;
    color: #333;
    background-color: rgba(255, 255, 255, 0.455); /*semi-transparent background*/
    font-size: 1.5rem;

}

.book-now-btn {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--mainColour);
    color: var(--textColour);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-decoration: none;
}

.book-now-btn:hover {
    background-color: var(--mainColourLight);
    transform: scale(1.05);
}


.services-sections {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
    background-color: #f0f0f0;
    width: 100%;
  }
  
  .service-section {
    display: flex;
    align-items: center;
    padding: 2rem;
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #333;
    margin: 1rem 0;
    border-radius: 10px;
  }
  
  .service-section:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .service-image {
    width: 35%;
    height: auto;
    border-radius: 10px;
    margin: 0 2rem;
  }
  
  .service-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 50%;
    font-size: 2rem;
    line-height: 1.5;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .service-section {
      flex-direction: column;
      text-align: center;
    }
  
    .service-section:nth-child(even) {
      flex-direction: column;
    }
  
    .service-image, .service-text {
      width: 100%;
      margin: 1rem 0;
    }
  }


.service-button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--mainColour);
    color: var(--textColour);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.5rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .service-button:hover {
    background-color: var(--mainColourLight);
    transform: scale(1.05);
  }
  