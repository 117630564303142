.book-now-container {
  padding: 2rem;
  text-align: center;
  align-items: center;
  margin-top: 100px;
}

.book-now-container h1 {
  margin-bottom: 2rem;
}

.book-now-container button {
  border: none;
  cursor: pointer;
  background-color: bisque;
  border-radius: 5px;
  padding: .3rem .5rem;
  position: fixed;
  bottom: 20px;
  right: 20px; 
  z-index: 1000;
}

.book-now-container iframe {
  width: 100%;
  max-width: 1024px; 
  height: 1600px;
  border: none; 
}

